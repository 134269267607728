.cell {
    height: 56px;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
}
.wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
