/* addButton */

.addButton {
    display: flex;
    align-items: center;
    border: 2px solid var(--primary-400-base);
    width: 201px;
    min-height: 40px;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 20px;
    outline: none;
    cursor: pointer;
    background-color: transparent;
}

.addButton__text {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: var(--primary-400-base);
}

.addButton__icon {
    margin-left: auto;
    height: 24px;
    width: 24px;
    fill: var(--primary-400-base);
}

.addButton:focus {
    box-shadow: 0 0 0 2px var(--primary-500);
}
.addButton:active .addButton__text {
    color: var(--primary-500);
}

.addButton:active .addButton__icon {
    fill: var(--primary-500);
}
.addButton:disabled {
    cursor: auto;
    background-color: var(--whitebase);
}

.addButton:disabled .addButton__icon {
    fill: var(--black-100);
}

.addButton:disabled .addButton__text {
    color: var(--black-100);
}

.addButton--secondary {
    border-color: var(--secondary-400-base);
    background-color: var(--secondary-400-base);
}

.addButton--secondary .addButton__text {
    color: var(--whitebase);
}

.addButton--secondary .addButton__icon {
    fill: var(--whitebase);
}

.addButton--secondary:focus {
    box-shadow: 0 0 0 2px var(--secondary-500);
}

.addButton--secondary:active {
    border-color: var(--secondary-500);
    background-color: var(--secondary-500);
}

.addButton--secondary:active .addButton__text {
    color: var(--whitebase);
}

.addButton--secondary:active .addButton__icon {
    fill: var(--whitebase);
}

/*TODO it should be in wrapper. Not in button directly*/
.singlePackage__button {
    margin-top: 16px;
    margin-left: auto;
}
/* end addButton */
