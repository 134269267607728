.box {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 2px solid #1e90ff;
    margin-top: 50px;
}

.title {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 28px;
    color: var(--secondary-400-base);
    text-transform: uppercase;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-align: start;
    letter-spacing: normal;
    margin-bottom: 20px;
    margin-top: 30px;
}

.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(346px, 1fr));
    grid-gap: 1rem;
    justify-items: center;
}

.wrapper > div:nth-child(1n) {
    margin: 0;
}
