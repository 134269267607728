.label {
    margin: 0;
    padding: 0;
    display: flex;
    justify-self: start;
    align-items: center;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
}

.input {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

.icon {
    border-radius: 4px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    cursor: pointer;
    fill: var(--primary-400-base);
}

.input:disabled ~ .icon {
    fill: var(--black-100);
    cursor: auto;
}

.input:focus ~ .icon {
    box-shadow: 0 0 0 2px var(--primary-500);
}
