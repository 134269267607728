.popup {
    display: flex;
    align-items: stretch;
    background-color: var(--whitebase);
    padding: 0;
    position: relative;
    top: calc(50% - 236px);
    left: calc(50% - 350px);
    width: 726px;
    min-height: 472px;
}

.iconWrapper {
    position: relative;
    width: 64px;
    background-color: var(--primary-400-base);
}

.icon {
    position: absolute;
    width: 39px;
    height: 44px;
    top: 54px;
    left: calc(50% - 16.5px);
    fill: var(--whitebase);
}

.body {
    padding-top: 24px;
    padding-bottom: 42px;
    padding-right: 66px;
    padding-left: 64px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.closeButtonWrapper {
    align-self: flex-end;
}

.title {
    margin: 0;
    padding: 0;
    font-family: "Raleway", sans-serif;
    font-size: 37px;
    line-height: 52px;
    color: var(--black-base);
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-bottom: 9px;
}

.buttonWrapper {
    margin-top: 21px;
    display: flex;
    justify-content: space-between;
}

.margin {
    height: 9px;
}

.inner {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 444px;
}

.likWrapper {
    display: flex;
    flex-direction: column;
}

.link {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--primary-400-base);
    outline: none;
}

.link:focus {
    text-decoration: underline;
}
