.singlePackage__title {
    margin-left: 0;
}

.singlePackage {
    margin-left: 25px;
    padding-bottom: 50px;
}

.main {
    align-items: flex-start;
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;
    display: block;
    max-width: 100%;
    padding-left: 51px;
    padding-right: 51px;
}

.college_id {
    font-size: 21px;
}
