:root {
    --whitebase: #ffffff;

    --black-100: #e6e6e6;
    --black-200: #939393;
    --black-300: #666666;
    --black-base: #212121;

    --primary-100: #e9f4ff;
    --primary-200: #a5d3ff;
    --primary-300: #62b1ff;
    --primary-400-base: #1e90ff;
    --primary-500: #1873cc;
    --primary-600: #125699;
    --primary-700: #0f4880;

    --secondary-100: #ffebf5;
    --secondary-200: #ffaed7;
    --secondary-300: #ff71b9;
    --secondary-400-base: #ff349b;
    --secondary-500: #b3246d;
    --secondary-600: #66153e;
    --secondary-700: #330a1f;

    --tertiary-100: #ccc6fe;
    --tertiary-200: #bbb3fd;
    --tertiary-300: #8879fc;
    --tertiary-400-base: #5540fb;
    --tertiary-500: #3b2db0;
    --tertiary-600: #221a64;
    --tertiary-700: #110d32;

    --success-100: #d1eedd;
    --success-200: #a3ddbc;
    --success-300: #5dc389;
    --success-400-base: #18a957;
    --success-500: #11763d;
    --success-600: #0a4423;
    --success-700: #052211;

    --warning-100: #fff1d7;
    --warning-200: #ffe4af;
    --warning-300: #ffcf74;
    --warning-400-base: #ff8a38;
    --warning-500: #b38327;
    --warning-600: #664b16;
    --warning-700: #33250b;

    --error-100: #f9d0d9;
    --error-200: #f2a2b3;
    --error-300: #e95c7b;
    --error-400-base: #df1642;
    --error-500: #9c0f2e;
    --error-600: #59091a;
    --error-700: #2d040d;

    --whitebase-10: rgba(255, 255, 255, 0.1);
    --whitebase-20: rgba(255, 255, 255, 0.2);
    --black-30: rgba(0, 0, 0, 0.3);
    --whitebase-30: rgba(255, 255, 255, 0.3);

    --retina-2x: "(min-resolution: 144dpi), (min-resolution: 1.5dppx)";
    --RM: "Roboto Medium", sans-serif;
    --RR: "Roboto Regular", sans-serif;
    --RB: "Roboto Bold", sans-serif;

    --blue-400: hsl(200, 80%, 80%);
    --blue-500: hsl(200, 97%, 48%);
    --blue-600: hsl(200, 97%, 38%);
    --blue-700: hsl(216, 100%, 40%);

    --green-400: hsl(154, 50%, 75%);
    --green-600: hsl(154, 71%, 44%);
    --green-700: hsl(154, 71%, 34%);

    --white: hsl(0, 0%, 100%);

    --red-400: hsl(0, 70%, 85%);
    --red-700: hsl(0, 94%, 65%);

    --purple-400: hsl(288, 41%, 90%);
    --purple-700: hsl(288, 61%, 70%);

    --orange-400: hsl(36, 75%, 75%);
    --orange-700: hsl(36, 98%, 54%);

    --yellow-400: hsl(46, 70%, 80%);
    --yellow-700: hsl(46, 90%, 62%);

    --grey-100: hsl(240, 9%, 98%);
    --grey-200: hsl(220, 16%, 96%);
    --grey-300: hsl(240, 2%, 92%);
    --grey-400: hsl(240, 3%, 85%);
    --grey-500: hsl(0, 0%, 77%);
    --grey-600: hsl(0, 0%, 44%);

    --darkBlue-300: hsl(223, 12%, 89%);
    --darkBlue-400: hsl(214, 17%, 62%);
    --darkBlue-500: hsl(219, 13%, 48%);
    --darkBlue-600: hsl(219, 13%, 38%);
    --darkBlue-700: hsl(211, 22%, 26%);
}
