.arrowLink {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-right: 24px;
    background-color: transparent;
    position: relative;
    border: none;
    border-radius: 4px;
    text-decoration: none;
}

.arrowLinkIcon {
    width: 40px;
    height: 40px;
    fill: var(--secondary-400-base);
}

.arrowLink:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.arrowLink:disabled .arrowLink {
    fill: var(--black-100);
}
