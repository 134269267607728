.iframe {
    height: calc(100vh - 170px);
    width: 100%;
}

.top {
    display: flex;
    height: 60px;
}

.header {
    display: block;
    width: auto;
    padding-left: 77px;
    padding-right: 54px;
    padding-top: 0;
    padding-bottom: 20px;
    background-color: var(--primary-100);
}

.title {
    font-family: "Raleway", sans-serif;
    font-size: 37px;
    line-height: 1.41;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--black-base);
    margin: 0;
    padding: 0;
}

.text {
    font-family: "Raleway", sans-serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    color: var(--black-base);
    margin: 0;
    padding: 0;
    padding-bottom: 12px;
}

.buttonWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.buttonContainer > button {
    width: 150px;
    margin-right: 16px;
}

.buttonSaveGroup {
    margin-left: auto;
    display: flex;
}

.buttonSaveGroup button {
    margin-right: 17px;
}

.college_id {
    font-weight: bold;
    font-size: 21px;
}

.nameWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
