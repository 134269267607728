.wrapper {
    position: absolute;
    top: 20px;
    right: 22px;
    z-index: 20;
    width: 189px;
    min-height: 125px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 16px 24px 0 rgba(48, 49, 51, 0.1);
    background-color: var(--whitebase);
}

.list {
    list-style: none;
    margin: 0;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 23px;
    padding-bottom: 23px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.text {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
    margin-left: 8px;
    text-transform: capitalize;
}

button.button {
    outline: none;
}

.icon {
    height: 24px;
    width: 24px;
    fill: var(--primary-400-base);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-500);
}

.button:active .icon {
    fill: var(--primary-500);
}
