/* addButton */

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

button.button {
    outline: none;
}

.icon {
    height: 24px;
    width: 24px;
    fill: var(--black-300);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-500);
}
.button:active .icon {
    fill: var(--black-400);
}

.addButton:disabled {
    cursor: auto;
}

.addButton:disabled .icon {
    fill: var(--black-100);
}
