.tableWrapper {
    width: 100%;
    margin-top: 20px;
    position: relative;
}

.CellWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.table {
    width: 100%;
}

.headerCell {
    background-color: var(--primary-100);
    font-family: Raleway, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
    height: 56px;
    text-align: start;
}
.headerCell:first-child {
    padding-left: 25px;
}

.headerCell:last-child {
    padding-right: 25px;
}

.CellWrapperBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
