@import "variables.css";

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.body {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

body,
html {
    margin: 0;
    font-family: "Raleway", sans-serif;
    line-height: 19px;
    font-size: 16px;
    color: var(--black-base);
    font-weight: normal;
}
* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}
