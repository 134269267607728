.wrapper {
    position: relative;
    align-self: flex-start;
}
.title {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
    padding: 0;
    margin: 0;
    margin-bottom: 4px;
}

.input {
    width: 444px;
    height: 44px;
    border: solid 2px var(--black-100);
    padding-left: 16px;
    padding-right: 47px;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 44px;
    letter-spacing: normal;
    color: var(--black-base);
    outline: none;
}

.input::placeholder {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-200);
}

.input:focus {
    box-shadow: 0 0 0 2px var(--primary-500);
}

.input:disabled {
    color: var(--black-200);
}

.button {
    position: absolute;
    right: 14px;
    bottom: 12px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 4px;
    height: 19px;
    min-width: 19px;
    background-color: transparent;
    cursor: pointer;
    outline: none;
}

.icon {
    fill: var(--black-100);
    height: 19px;
    width: 19px;
}

.button:active .icon {
    fill: var(--black-200);
}

.button:focus {
    outline: none;
}

.button:active {
    outline: none;
}

.button:disabled .icon {
    fill: var(--black-100);
    cursor: auto;
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-500);
}
