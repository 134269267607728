/* popup */

.root {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    background-color: var(--whitebase);
    padding: 0;
    position: relative;
    top: calc(50% - 170px);
    left: calc(50% - 350px);
    width: 700px;
    min-height: 339px;
}

.wrapper {
    position: relative;
    width: 64px;
    background-color: var(--primary-400-base);
}

.icon {
    position: absolute;
    top: 59px;
    left: calc(50% - 19.5px);
    fill: var(--whitebase);
}

.body {
    padding-top: 54px;
    padding-bottom: 32px;
    padding-right: 43px;
    padding-left: 64px;
    display: flex;
    width: 636px;
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.body > textarea {
    margin-top: 25px;
}

.title {
    margin: 0;
    padding: 0;
    font-family: "Raleway", sans-serif;
    font-size: 37px;
    line-height: 52px;
    color: var(--black-base);
    max-width: 355px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: "Raleway", sans-serif;
    font-size: 21px;
    line-height: 32px;
    text-align: start;
    color: var(--black-base);
    padding: 0;
    margin: 0;
    margin-top: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.text b {
    font-weight: 700;
}

.text > span:last-child {
    margin-left: 65px;
}

.footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.footer > button {
    outline: none;
}

/* end popup */

/* ----popupButton------------- */

.button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 8px 16px;
    border: 2px solid var(--primary-400-base);
    border-radius: 4px;
    height: 40px;
    min-width: 88px;
    background-color: var(--whitebase);
    color: var(--primary-400-base);
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.button:active {
    background-color: var(--black-100);
}

.button:disabled {
    background-color: var(--black-100);
    color: var(--primary-200);
    cursor: auto;
    border-color: var(--black-100);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-500);
}

.ok {
    background-color: var(--secondary-400-base);
    border-color: var(--secondary-400-base);
    color: var(--whitebase);
    margin-left: auto;
}

.ok:active {
    background-color: var(--secondary-300);
    border-color: var(--secondary-300);
}
/* ----end popupButton------------- */
