.wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.link {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px;
    margin: 0;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
    color: var(--primary-400-base);
}

.link:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}
